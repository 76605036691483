import { useAssistantConfigs } from "../../../Queries/useAssistantConfigs";
import { AdminButton, AdminCategoryTitle, AdminInputError, AdminSettingContainer, AdminSettingSelectBox, AdminSettingText } from "../../AdminPage-styling";
import { useSelectedAssistant } from "../../../Queries/useSelectedAssistant";
import { useShowAssistantConfig } from "../../../Queries/useShowAssistantConfig";
import AssistantBasicInfoConfig from "./AssistantBasicInfoConfig";
import AssistantBehaviorConfig from "./AssistantBehaviorConfig";
import { useAssistantConfigForModule } from "../../../Queries/useAssistantConfigForModule";
import { useForm } from "react-hook-form";
import { assistantConfigSelectSchema, TAssistantConfigSelectSchema } from "../../../Types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useActiveModuleAssistantConfigMutation } from "../../../Mutations/ActiveModuleAssistantConfigMutation";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useUserMeta } from "../../../Queries/useUserMeta";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import Swal from "sweetalert2";

const AssistantConfig = () => {

  const queryClient = useQueryClient();

  const {data: userMeta} = useUserMeta();
  const {data: assistantConfigs} = useAssistantConfigs(userMeta?.unit_id);
  const {selectedAssistant, updateSelectedAssistant} = useSelectedAssistant();
  const {showAssistantConfig, updateShowAssistantConfig} = useShowAssistantConfig();
  const {data: internalAssistantConfig} = useAssistantConfigForModule('internal_chatbot');
  const {data: feedbackAssistantConfig} = useAssistantConfigForModule('feedback');
  const {data: marketingAssistantConfig} = useAssistantConfigForModule('marketing');

  const activeModuleAssistantConfigMutation = useActiveModuleAssistantConfigMutation();

  const [localSelectedAssistantId, setLocalSelectedAssistantId] = useState<string | null>(selectedAssistant?.id || (showAssistantConfig ? '' : 'none'));

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    reset,
  } = useForm<TAssistantConfigSelectSchema>({
    resolver: zodResolver(assistantConfigSelectSchema),
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      reset({
        internal_assistant_config_id: internalAssistantConfig?.id ?? '',
        feedback_assistant_config_id: feedbackAssistantConfig?.id ?? '',
        marketing_assistant_config_id: marketingAssistantConfig?.id ?? '',
      });
    }, 200); // Small delay to batch updates
  
    // Cleanup timeout if dependencies change before timeout executes
    return () => clearTimeout(timeoutId);
  }, [
    userMeta?.unit_id, 
    internalAssistantConfig, 
    feedbackAssistantConfig, 
    marketingAssistantConfig, 
    reset
  ]);

  const onSubmit = async (data: TAssistantConfigSelectSchema) => {
    const mutations: AxiosResponse<any, any>[] = [];
    if (data.internal_assistant_config_id !== internalAssistantConfig?.id) {
      const internalRequestBody = {
        assistant_config_id: data.internal_assistant_config_id,
        module_name: 'internal_chatbot',
      }
      mutations.push(
      await activeModuleAssistantConfigMutation.mutateAsync(internalRequestBody, {
        onSuccess: () => {
          queryClient.setQueryData(['assistant_config', 'for_module', { module_name: 'internal_chatbot' }], assistantConfigs?.find(config => config.id === data.internal_assistant_config_id));
        }
      })
    );
    }
    if (data.feedback_assistant_config_id !== feedbackAssistantConfig?.id) {
      const feedbackRequestBody = {
        assistant_config_id: data.feedback_assistant_config_id,
        module_name: 'feedback',
      }
      mutations.push(
      await activeModuleAssistantConfigMutation.mutateAsync(feedbackRequestBody, {
        onSuccess: () => {
          queryClient.setQueryData(['assistant_config', 'for_module', { module_name: 'feedback' }], assistantConfigs?.find(config => config.id === data.feedback_assistant_config_id));
        }
      })
    );
    }
    if (data.marketing_assistant_config_id !== marketingAssistantConfig?.id) {
      const marketingRequestBody = {
        assistant_config_id: data.marketing_assistant_config_id,
        module_name: 'marketing',
      }
      mutations.push(
      await activeModuleAssistantConfigMutation.mutateAsync(marketingRequestBody, {
        onSuccess: () => {
          queryClient.setQueryData(['assistant_config', 'for_module', { module_name: 'marketing' }], assistantConfigs?.find(config => config.id === data.marketing_assistant_config_id));
        }
      })
    );
    }
    await Promise.all(mutations).then((results) => {
      if (results.every(result => result.status === 200)) {
        toast.success('Assistentval sparade!');
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Något gick fel, försök igen senare.',
        });
      }
    });
  }

  const handleSelectChange = (e: { target: { value: string; }; }) => {
    const value = e.target.value;
    setLocalSelectedAssistantId(value);

    if (value === 'none') {
      updateSelectedAssistant(null);
      updateShowAssistantConfig(false);
    } else {
      const selected = assistantConfigs?.find(assistant => assistant.id === value);
      updateSelectedAssistant(selected || null);
      updateShowAssistantConfig(true);
    }
  };

  return (
    <AdminSettingContainer>
      <AdminCategoryTitle>Assistentkonfiguration</AdminCategoryTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AdminSettingContainer>
          <AdminSettingText>Välj intern assistent</AdminSettingText>
          <AdminSettingSelectBox
          // defaultValue={internalAssistantConfig?.id || ''}
          {...register('internal_assistant_config_id')}
          >
            <option style={{color: '#666', fontStyle: 'italic'}} value="">Välj intern assistent</option>
            {assistantConfigs?.map((config) => (
              <option key={config.id} value={config.id}>{config.config_name}</option>
            ))}
          </AdminSettingSelectBox>
          {errors.internal_assistant_config_id && 
            <AdminInputError>{errors.internal_assistant_config_id.message}</AdminInputError>}
        </AdminSettingContainer>
        <AdminSettingContainer>
          <AdminSettingText>Välj feedback assistent</AdminSettingText>
          <AdminSettingSelectBox
          // defaultValue={feedbackAssistantConfig?.id || ''}
          {...register('feedback_assistant_config_id')}
          >
            <option style={{color: '#666', fontStyle: 'italic'}} value="">Välj feedback assistent</option>
            {assistantConfigs?.map((config) => (
              <option key={config.id} value={config.id}>{config.config_name}</option>
            ))}
          </AdminSettingSelectBox>
          {errors.feedback_assistant_config_id && 
            <AdminInputError>{errors.feedback_assistant_config_id.message}</AdminInputError>}
        </AdminSettingContainer>
        <AdminSettingContainer>
          <AdminSettingText>Välj marknadsföringsassistent</AdminSettingText>
          <AdminSettingSelectBox
          // defaultValue={marketingAssistantConfig?.id || ''}
          {...register('marketing_assistant_config_id')}
          >
            <option style={{color: '#666', fontStyle: 'italic'}} value="">Välj marknadsföringsassistent</option>
            {assistantConfigs?.map((config) => (
              <option key={config.id} value={config.id}>{config.config_name}</option>
            ))}
          </AdminSettingSelectBox>
          {errors.marketing_assistant_config_id && 
            <AdminInputError>{errors.marketing_assistant_config_id.message}</AdminInputError>}
        </AdminSettingContainer>
        <AdminButton type="submit" disabled={isSubmitting}>Spara val</AdminButton>
      </form>
      <AdminSettingContainer>
        <AdminSettingText style={{marginTop: '3rem', fontSize: '1.3rem'}}>Välj en assistent att redigera</AdminSettingText>
        <AdminSettingSelectBox
        style={{fontSize: '1.2rem'}}
        value={localSelectedAssistantId || ''}
        onChange={handleSelectChange}
        >
          <option style={{color: '#666', fontStyle: 'italic'}} value="none">Välj assistent</option>
          <option style={{ fontWeight: 'bold'}} value="">Ny assistent</option>
          {assistantConfigs?.map((config) => (
            <option key={config.id} value={config.id}>{config.config_name}</option>
          ))}
        </AdminSettingSelectBox>
      </AdminSettingContainer>
      {showAssistantConfig && (
        <>
          <AssistantBasicInfoConfig />
          <AssistantBehaviorConfig />
        </>
      )}

      {/* <AdminButton onClick={toggleAssistantConfigPopUp}>
        Skapa ny konfiguration
      </AdminButton> */}
    </AdminSettingContainer>
  );
}

export default AssistantConfig;