import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";



export const useTextTypes = () => {
  return useQuery<string[], Error>({
    queryKey: ['text_type', 'all'],
    queryFn: (): Promise<string[]> => ax.get('/search/text_types/').then(res => res.data),
    enabled: true,
    refetchOnReconnect: true,
  });
};