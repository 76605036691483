import { useQueries } from "@tanstack/react-query";
import { ax } from "../Utils";
import { SearchResult } from "../Types";


const fetchMessageReferences = async (messageGroupId: string): Promise<SearchResult[]> => {
  return await ax.get(`/assistant/get_references?message_group_id=${messageGroupId}`).then((response) => response.data);
}

export const useAssistantMessageReferences = (messageGroupIds: string[]) => {
  const queries = useQueries({
    queries: messageGroupIds.map((messageGroupId) => ({
      queryKey: ['message_references', { messageGroupId }],
      queryFn: () => fetchMessageReferences(messageGroupId),
      enabled: !!messageGroupId,
    })),
  });

  return queries.map(query => query.data || []);
}
