import styled from "styled-components";

export const ReferencePopupContainer = styled.div`
  max-width: 80vw;
  max-height: 80vh;
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
`;

export const ReferenceText = styled.div`
  padding-top: 8px;
  margin-bottom: 2px;
  padding-left: 11px;
  padding-right: 11px;
  color: #000;
  white-space: pre-line;
`;

export const ReferenceTitle = styled.h1`
  font-size: 24px;
  font-weight: 400;
  padding-left: 10px;
  color: #000;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`;

export const ReferenceFaqQuestion = styled.div`
  padding-top: 8px;
  margin-bottom: 2px;
  padding-left: 11px;
  padding-right: 11px;
  color: #000;
  font-weight: 500;
  font-size: 1.1rem;
  white-space: pre-line;
`;

export const ReferenceTextContainer = styled.div`
  width: 100%;
`;