import { useQuery } from "@tanstack/react-query";
import { ChatbotConfig } from "../Types";
import { ax } from "../Utils";


export const useChatbotConfigList = () => {
  return useQuery<ChatbotConfig[]>({
    queryKey: ['chatbot_config', 'all'],
    queryFn: () => ax.get('/chatbot_config/all/').then(res => res.data),
    refetchOnReconnect: true,
  });
}