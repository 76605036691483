import { zodResolver } from "@hookform/resolvers/zod";
import { useUserMeta } from "../../Queries/useUserMeta";
import { ax } from "../../Utils";
import { AdminButton, AdminCategoryTitle, AdminInputError, AdminSettingContainer, AdminSettingForm, AdminSettingInput, AdminSettingText, AdminSettingTextarea, SmallTextSpan } from "../AdminPage-styling";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { AccessibleUnitOrg, TUnitSchema, unitSchema } from "../../Types";
import { toast } from "react-toastify";
import { queryClient } from "../../lib/queryClient";


export default function UnitInfo() {

  const { data: userMeta } = useUserMeta();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<TUnitSchema>({
    resolver: zodResolver(unitSchema),
  });

  const onSubmit = async (data: TUnitSchema) => {
    console.log("submitting form", data);
    if (data.name !== userMeta?.unit_name || data.description !== userMeta?.unit_business_description) {
      const requestBody = {
        id: userMeta?.unit_id,
        name: data.name,
        description: data.description,
      };
      try {
        await ax.patch('/unit', requestBody)
          .then(() => {
            queryClient.setQueryData(['users', 'meta'], {
              ...userMeta,
              unit_name: data.name,
              unit_business_description: data.description,
            });
            queryClient.setQueryData(['users', 'accessible-unit-org'], (oldData: AccessibleUnitOrg[]) => {
              if (!oldData) return oldData;
    
              return oldData.map(org => {
                if (org.org_id === userMeta?.org_id) {
                  return {
                    ...org,
                    units: org.units.map(u => 
                      u.unit_id === userMeta?.unit_id 
                        ? { ...u, unit_name: data.name }
                        : u
                    )
                  };
                }
                return org;
              });
            })
            toast.success("Avdelningen har uppdaterats!");
            reset();
          })
      }
      catch (error) {
        Swal.fire({
          title: "Förfrågan misslyckades!",
          text: "Kunde inte uppdatera avdelningen. Försök igen senare.",
          icon: "error"
        });
        return;
      }
    }
  };

  return (
    <form style={{ width: '100%', marginTop: '3rem' }} onSubmit={handleSubmit(onSubmit)}>
      <AdminCategoryTitle>Avdelningsinformation</AdminCategoryTitle>
      <AdminSettingContainer>
        <AdminSettingText>Namn</AdminSettingText>
        <AdminSettingInput
          type="text"
          defaultValue={userMeta?.unit_name}
          {...register("name")}
        />
        {errors.name && (
          <AdminInputError>{`${errors.name.message}`}</AdminInputError>
        )}
      </AdminSettingContainer>
      <AdminSettingContainer>
        <AdminSettingText>Beskrivning <SmallTextSpan>(Valfritt men rekommenderat då det hjälper assistenten att förstå sin uppgift)</SmallTextSpan></AdminSettingText>
        <AdminSettingTextarea
          placeholder="Skriv en kort beskrivning av ditt företag här..."
          rows={5}
          defaultValue={userMeta?.unit_business_description}
          {...register("description")}
        />
      </AdminSettingContainer>
      <AdminButton 
        type="submit"
        disabled={isSubmitting}
      >
        Spara
      </AdminButton>
    </form>
  );
}