import { useEffect } from 'react';

const ZoidChatbotWidget = ({ clientKey }) => {
  useEffect(() => {
    let chatbotInstance = null;

    // Function to remove existing elements
    const cleanup = () => {
      console.log('Cleanup: Starting cleanup process');
      // Remove chatbot container
      const chatbotContainer = document.querySelector('[data-zoid-chatbot-widget]');
      if (chatbotContainer) {
        chatbotContainer.remove();
        console.log('Cleanup: Removed chatbot container');
      }

      // Remove toggle button
      const toggleButton = document.querySelector('button[data-chatbot-toggle]');
      if (toggleButton) {
        toggleButton.remove();
        console.log('Cleanup: Removed toggle button');
      }

      // Clean up global Zoid instances
      if (window.zoid) {
        window.zoid.destroyAll();
        console.log('Cleanup: Destroyed Zoid instances');
      }
    };

    const loadZoidScript = () => {
      console.log('Loading: Starting script load process');
      
      return new Promise((resolve, reject) => {
        // If Zoid is already loaded, resolve immediately
        if (window.zoid) {
          console.log('Loading: Zoid already loaded');
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/zoid/dist/zoid.min.js';
        script.async = true;
        
        script.onload = () => {
          console.log('Loading: Zoid script loaded');
          resolve();
        };
        
        script.onerror = () => {
          console.error('Loading: Failed to load Zoid script');
          reject(new Error('Failed to load Zoid script'));
        };

        document.body.appendChild(script);
      });
    };

    const loadComponentScript = () => {
      console.log('Loading: Starting component script load');
      
      return new Promise((resolve, reject) => {
        const componentScript = document.createElement('script');
        componentScript.src = 'https://app.ragge.ai/scripts/zoidComponent.js';
        componentScript.async = true;
        
        componentScript.onload = () => {
          console.log('Loading: Component script loaded');
          resolve();
        };
        
        componentScript.onerror = () => {
          console.error('Loading: Failed to load component script');
          reject(new Error('Failed to load component script'));
        };

        document.body.appendChild(componentScript);
      });
    };

    const initializeChatbot = async () => {
      console.log('Init: Starting chatbot initialization');
      
      if (!window.Chatbot) {
        console.error('Init: Chatbot not found in window object');
        return;
      }

      try {
        chatbotInstance = window.Chatbot({
          clientKey: clientKey,
          onLoad: () => {
            console.log('Init: Chatbot content loaded');
          },
        });

        await chatbotInstance.render(document.body);
        console.log('Init: Chatbot rendered successfully');
        
      } catch (error) {
        console.error('Init: Error initializing chatbot:', error);
      }
    };

    const initialize = async () => {
      try {
        cleanup();
        await loadZoidScript();
        await loadComponentScript();
        await initializeChatbot();
      } catch (error) {
        console.error('Failed to initialize chatbot:', error);
      }
    };

    initialize();

    // Cleanup function
    return () => {
      console.log('Cleanup: Component unmounting');
      cleanup();
      
      // Remove scripts
      const scripts = document.querySelectorAll('script');
      scripts.forEach(script => {
        if (
          script.src.includes('zoid.min.js') || 
          script.src.includes('zoidComponent.js')
        ) {
          script.remove();
          console.log('Cleanup: Removed script:', script.src);
        }
      });

      if (chatbotInstance && typeof chatbotInstance.destroy === 'function') {
        chatbotInstance.destroy();
        console.log('Cleanup: Destroyed chatbot instance');
      }
    };
  }, [clientKey]);

  return null;
};

export default ZoidChatbotWidget;