import { UUID } from "crypto";
import { z } from "zod";

// export type Errand = {
//   title: string;
//   id: number;
//   created_at: string;
//   last_chat_item_text: string;
// };

export type Template = {
  id: string;
  title: string;
  text: string;
  last_updated_at: string;
  created_at: string;
  // text_1: string;
  // text_2: string;
  // text_3: string;
  // checked: boolean;
};

// export type HistResponse = {
//   id: number;
//   text: string;
//   checked: boolean;
//   errand_id: number;
// };

export type FAQ = {
  id: string;
  title: string;
  question_text: string;
  answer_text: string;
  last_updated_at: string;
  created_at: string;
  // answer_id: number;
  // question_id: number;
  // checked: boolean;
};

// export type Response = {
//   id: number;
//   text: string;
//   class_type: string;
//   created_at: string;
//   created_by: string;
// };

// export type Prompt = {
//   id: number;
//   prompt: string;
//   system_prompt: string;
//   created_at: string;
// };

// export type Draft = {
//   text: string;
//   errand_id: number;
// };

export type User = {
  first_name: string;
  last_name: string;
  phone: string;
  interface_language_id: string;
  assistant_language_id: string;
  org_id: string;
  unit_id: string;
  id: string;
  email: string;
  is_superuser: boolean;
};

export type UserMeta = {
  user_id: string;
  first_name: string;
  last_name: string;
  assistant_language: string;
  interface_language: string;
  org_name: string;
  org_id: string;
  unit_name: string;
  unit_id: string;
  org_business_description: string;
  unit_business_description: string;
}

export type Organization = {
  id: string;
  name: string;
  business_description: string;
  industry_id: string;
  pricing_plan_id: string;
  default_assistant_language_id: string;
  default_interface_language_id: string;
  country_code: string;
  country_name: string;
};

export type Unit = {
  id: string;
  name: string;
  description: string;
  organization_id: string;
  division_id: string;
};

export type UserUnitAccess = {
  user_id: string;
  user_email: string;
  unit_id: string;
  unit_name: string;
  roles: {
    reader: UUA_Role;
    feedback: UUA_Role;
    marketing: UUA_Role;
    admin: UUA_Role;
  }
};

export type UUA_Role = {
  id: string;
  role_name: string;
  role_id: string;
}

export type Role = {
  id: string;
  name: string;
  description: string;
}

export type AccessibleUnitOrg = {
  org_id: string;
  org_name: string;
  units: AccessibleUnit[];
}

export type AccessibleUnit = {
  unit_id: string;
  unit_name: string;
}

export type KPIs = {
  nbr_open_errands: number;
  nbr_total_errands: number;
  nbr_closed_errands_last_30_days: number;
  nbr_responses_last_30_days: number;
};

export type Marketing = {
  title: string;
  text: string;
  body: string;
  text_type: string;
  id: string;
  created_at: string;
  last_updated_at: string;
};

export type FeedbackType = "email" | "review" | "external_chatbot";// | null;

export type FeedbackDataMap = {
  email: Email[];
  external_chatbot: ChatbotMessage[];
  review: Review[];
};

export type FeedbackThread =
  | { feedback_type: 'email'; data: Email[] }
  | { feedback_type: 'review'; data: Review[] }
  | { feedback_type: 'external_chatbot'; data: ChatbotMessage[] };

export type Feedback = {
  id: string;
  title: string;
  feedback_source_type_name: FeedbackType;
  summary: string;
  last_message_content: string;
  last_message_sent_at: string;
};

export type Email = {
  last_updated_at: string;
  id: string;
  thread_id: string;
  message_id: string;
  provider_message_id: string;
  tracking_id: string;
  subject: string;
  body: string;
  quote_body: string;
  new_body: string;
  new_text: string;
  new_body_safe: string;
  from_attendee: {
    display_name: string;
    identifier: string;
  };
  to_attendees: [{
    display_name: string;
    identifier: string;
  }];
  cc_attendees: [{
    display_name: string;
    identifier: string;
  }];
  bcc_attendees: [{
    display_name: string;
    identifier: string;
  }];
  has_attachments: boolean;
  attachments: [{
    id: string;
    mime_type: string;
    name: string;
    extension: string;
    size: number;
  }];
  reply_to_message_id: string;
  sent_at: string;
  role: string;
  feedback_source_id: string;
  feedback_id: string;
  feedback_source_email: string;
  feedback_source_email_base: string;
}

export type ChatbotMessage = {
  id: string;
  user_role: string;
  content: string;
  created_at: string;
}

export type Review = {
  id: string;
  title: string;
  content: string;
  rating: number;
  created_at: string;
}

export type Web = {
  id: string;
  text: string;
  url: string;
  summary: string;
  web_page_id: string;
  last_updated_at: string;
  created_at: string;
}

export type Product = {
  id: string;
  external_id: string;
  sku: string;
  name: string;
  product_type: string;
  product_group: string;
  product_sub_group: string;
  description: string;
  price: number;
  current_price: number;
  currency: string;
  in_stock: boolean;
  specifications: string;
  image_url: string;
  url: string;
  text: string;
  web_page_id: string;
  last_updated_at: string;
  created_at: string;
}

export type File = {
  id: string;
  title: string;
  file_type: string;
  description: string;
  file_storage_url: string;
  text: string;
  can_be_used_by_external_chatbot: boolean;
  last_updated_at: string;
  created_at: string;
}

export type CustomData = {
  id: string;
  last_updated_at: string;
  obj: any;
}

export type SearchResult = {
  id: string,
  last_updated_at: string,
  score: number,
  title: string,
  overview: string,
  class_name: string,
  custom_data_model_name: string,
  template: Template,
  faq: FAQ,
  web: Web,
  marketing: Marketing,
  product: Product,
  file: File,
  custom_data: CustomData,
  feedback: Feedback,
};

export type Message = {
  id: number;
  feedback_id: number;
  role: string;
  text: string;
  raw_body: string;
  body: string;
  quote_body: string;
  receiver_contact_info: [{
    name: string;
    email: string;
  }];
  sender_contact_info: {
    name: string;
    email: string;
  };
  sent_at: string;
  last_updated_at: string;
};

export type PricingPlan = {
  id: string;
  name: string;
  fixed_price_per_month: "";
  nbr_max_users: string;
  nbr_copilot_drafts: string;
};

export type Language = {
  id: string;
  name: string;
  code: string;
};

export type Industry = {
  name: string;
  id: string;
};

export type Inbox = {
  config: {
    email: string;
  };
  id: number;
  name: string;
  unit_id: string;
  last_refreshed_at: string;
  activated: boolean;
  sync_from_date: string;
  status: string;
};


export type DataModel = {
  id: string;
  name: string;
  description: string;
  object_schema: any;
};

export type WebScrapeConfig = {
  id: string;
  start_url: string;
  name: string;
  follow_links: boolean;
  //only_follow_start_url_children: boolean;
  refresh_interval: number;
  data_model_id: string;
  last_updated_at: string;
};

export type AssistantConfig = {
  id: string;
  config_name: string;
  config_description: string;
  name: string;
  background: string;
  goal: string;
  purpose: string;
  scope: string;
  tonality: string;
  logo_url: string;
  default_assistant_language_id: string;
  unit_id: string;
};

export type ChatbotConfig = {
  api_key: string;
  id: string;
  theme: string;
  height: number;
  width: number;
  assistant_config_id: string;
  config_name: string;
  valid_domains: string[];
};

export type ChatbotSettings = {
  height: number;
  width: number;
  theme: string;
  assistant_name: string;
  assistant_logo_url: string;
  valid_domains: string[];
};

export const signUpSchema = z
  .object({
    pricingPlanId: z.string().uuid(),
    companyName: z.string().min(1, "Företagsnamn krävs"),
    industryId: z.string().uuid(),
    defaultAssistantLanguageId: z.string().uuid(),
    defaultInterfaceLanguageId: z.string().uuid(),
    country: z.string().min(1, "Land krävs"),

    firstName: z.string().min(1, "Förnamn krävs"),
    lastName: z.string().min(1, "Efternamn krävs"),
    email: z.string().email(),
    phone: z.string().min(9, "Ogiltigt telefonnummer"),
    password: z.string()
      .min(8, "Lösenordet måste vara minst 8 tecken långt")
      .superRefine((value, ctx) => {
        if (!/[A-Z]/.test(value)) {
          ctx.addIssue({
            code: 'custom',
            message: "Lösenordet måste innehålla minst en stor bokstav",
          });
        }
        if (!/[a-z]/.test(value)) {
          ctx.addIssue({
            code: 'custom',
            message: "Lösenordet måste innehålla minst en liten bokstav",
          });
        }
        if (!/\d/.test(value)) {
          ctx.addIssue({
            code: 'custom',
            message: "Lösenordet måste innehålla minst en siffra",
          });
        }
        if (!/\W/.test(value)) {
          ctx.addIssue({
            code: 'custom',
            message: "Lösenordet måste innehålla minst ett specialtecken (t.ex. !@#$%^&*)",
          });
        }
      }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Upprepat lösenord måste matcha lösenordet",
    path: ["confirmPassword"],
  });

export type TSignUpSchema = z.infer<typeof signUpSchema>;

export const forgotPasswordSchema = z
  .object({
    email: z.string().email("Ogiltig e-postadress"),
  });

export type TForgotPasswordSchema = z.infer<typeof forgotPasswordSchema>;

export const resetPasswordSchema = z
  .object({
    password: z.string()
    .min(8, "Lösenordet måste vara minst 8 tecken långt")
    .superRefine((value, ctx) => {
      if (!/[A-Z]/.test(value)) {
        ctx.addIssue({
          code: 'custom',
          message: "Lösenordet måste innehålla minst en stor bokstav",
        });
      }
      if (!/[a-z]/.test(value)) {
        ctx.addIssue({
          code: 'custom',
          message: "Lösenordet måste innehålla minst en liten bokstav",
        });
      }
      if (!/\d/.test(value)) {
        ctx.addIssue({
          code: 'custom',
          message: "Lösenordet måste innehålla minst en siffra",
        });
      }
      if (!/\W/.test(value)) {
        ctx.addIssue({
          code: 'custom',
          message: "Lösenordet måste innehålla minst ett specialtecken (!@#$%^&*)",
        });
      }
    }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Upprepat lösenord måste matcha lösenordet",
    path: ["confirmPassword"],
  });

export type TResetPasswordSchema = z.infer<typeof resetPasswordSchema>;

export const addUserSchema = z
  .object({
    existingUserId: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().optional(),
    reader: z.boolean(),
    feedback: z.boolean(),
    marketing: z.boolean(),
    admin: z.boolean(),
  })
  .superRefine((data, ctx) => {
    console.log("data");
    if (!data.existingUserId) {
      // Validate firstName
      if (!data.firstName || data.firstName.trim().length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['firstName'],
          message: 'Förnamn krävs',
        });
      }
      // Validate lastName
      if (!data.lastName || data.lastName.trim().length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['lastName'],
          message: 'Efternamn krävs',
        });
      }
      // Validate email
      if (!data.email || data.email.trim().length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['email'],
          message: 'E-postadress krävs',
        });
      } else if (!z.string().email().safeParse(data.email).success) {
        // Additional email format validation
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_string,
          path: ['email'],
          validation: 'email',
          message: 'Ogiltig e-postadress',
        });
      }
    }
  });

export type TAddUserSchema = z.infer<typeof addUserSchema>;

export const websiteSchema = z
  .object({
    website: z.string().url("Ogiltig webbadress"),
    name: z.string().min(1, "Namn krävs"),
    follow_links: z.boolean().optional(),
    only_follow_start_url_children: z.boolean().optional(),
    refresh_interval: z.number({invalid_type_error: "Intervall måste vara ett heltal"}).int("Intervall måste vara ett heltal").positive("Intervall måste vara positivt").optional(),
    data_model_id: z.string().uuid().optional(),
  });

export type TWebsiteSchema = z.infer<typeof websiteSchema>;

export const addInboxSchema = z
  .object({
    email: z.string().email("Ogiltig e-postadress"),
    inboxName: z.string().min(1, "Inkorgen behöver ett namn"),
    sync_from_date: z.string().date("Datum krävs i efterfrågat format"),
  });

export type TAddInboxSchema = z.infer<typeof addInboxSchema>;

export const assistantConfigSchema = z
  .object({
    config_name: z.string().min(1, "Namn krävs"),
    config_description: z.string().optional(),
    name: z.string().optional(),
    background: z.string().min(1, "Assistenten behöver en bakgrund!"),
    goal: z.string().min(1, "Assistenten behöver ett mål!"),
    purpose: z.string().min(1, "Assistenten behöver ett syfte!"),
    scope: z.string().optional(),
    tonality: z.string().optional(),
    logo_url: z.string().url("Ogiltig url").optional(),
    default_assistant_language_id: z.string().uuid(),
  });

export type TAssistantConfigSchema = z.infer<typeof assistantConfigSchema>;

export const chatbotBasicInfoSchema = z
  .object({
    config_name: z.string().min(1, "Namn krävs"),
    description: z.string().optional(),
    default_language_id: z.string().uuid("Standardspråk krävs"),
    valid_domains: z.union([
      z.string().url("Ogiltig url"),
      z.undefined(),
      z.null(),
      z.literal(''),
    ]).optional(),
  });

export type TChatbotBasicInfoSchema = z.infer<typeof chatbotBasicInfoSchema>;

export const chatbotAppearanceSchema = z
  .object({
    bot_name: z.string().min(1, "Namn krävs"),
    primary_color: z.string().regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Ogiltig färg"),
    logo_url: z.union([
      z.string().url("Ogiltig url"),
      z.undefined(),
      z.null(),
      z.literal(''),
    ]).optional(),
  });

export type TChatbotAppearanceSchema = z.infer<typeof chatbotAppearanceSchema>;

export const chatbotBehaviorSchema = z
  .object({
    background: z.string().optional(),
    goal: z.string().optional(),
    purpose: z.string().optional(),
    tonality: z.string().optional(),
    scope: z.string().optional(),
  });

export type TChatbotBehaviorSchema = z.infer<typeof chatbotBehaviorSchema>;

export const assistantBasicInfoSchema = z
  .object({
    config_name: z.string().min(1, "Namn krävs"),
    description: z.string().optional(),
    name: z.string().min(1, "Assistenten behöver ett namn"),
    default_language_id: z.string().uuid("Standardspråk krävs"),
    logo_url: z.union([
      z.string().url("Ogiltig url"),
      z.undefined(),
      z.null(),
      z.literal(''),
    ]).optional(),
  });

export type TAssistantBasicInfoSchema = z.infer<typeof assistantBasicInfoSchema>;

export const assistantConfigSelectSchema = z
  .object({
    internal_assistant_config_id: z.string().uuid("Intern assistent krävs"),
    feedback_assistant_config_id: z.string().uuid("Feedback assistent krävs"),
    marketing_assistant_config_id: z.string().uuid("Marknadsföringsassistent krävs"),
  });

export type TAssistantConfigSelectSchema = z.infer<typeof assistantConfigSelectSchema>;

export const unitSchema = z
  .object({
    name: z.string().min(1, "Namn krävs"),
    description: z.string().optional(),
  });

export type TUnitSchema = z.infer<typeof unitSchema>;