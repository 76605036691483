import { SearchResult } from "../Types";
import {
  PopupOverlay,
  CloseButton,
  CloseErrandWindow,
  PopUpTitleContainer,
  ErrandCardTitle,
  MessageTitleContainer,
  MessageTitle,
  MessageContainer,
} from "../ErrandsPage/ErrandsPage-styling"
import { ChatItemText } from "../CataloguePage/CataloguePage-styling";
import { ReferenceFaqQuestion, ReferencePopupContainer, ReferenceText, ReferenceTextContainer, ReferenceTitle } from "./ReferencePopup-styling";

type ReferencePopupProps = {
  references: SearchResult[] | undefined;
  onClose: () => void;
}

const ReferencePopup = ({ references, onClose }: ReferencePopupProps) => {
  
  
  return (
    <PopupOverlay onClick={onClose}>
      <ReferencePopupContainer onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
        <PopUpTitleContainer>Källor</PopUpTitleContainer>
        <CloseButton onClick={onClose} />
        <div>
          {references ?
            references.map((reference) => (
              reference.template ?
              <div key={reference.id}>
                <MessageTitleContainer>
                  <ReferenceTitle>Mall {reference.template.title}</ReferenceTitle>
                </MessageTitleContainer>
                <ReferenceTextContainer>
                  <ReferenceText>{reference.template.text}</ReferenceText>
                </ReferenceTextContainer>
              </div>
              : reference.faq ?
                <div key={reference.id}>
                  <MessageTitleContainer>
                    <ReferenceTitle>FAQ - {reference.faq.title}</ReferenceTitle>
                  </MessageTitleContainer>
                  <ReferenceTextContainer>
                    <ReferenceFaqQuestion>Fråga</ReferenceFaqQuestion>
                    <ReferenceText>{reference.faq.question_text}</ReferenceText>
                  </ReferenceTextContainer>
                  <ReferenceTextContainer>
                    <ReferenceFaqQuestion>Svar</ReferenceFaqQuestion>
                    <ReferenceText>{reference.faq.answer_text}</ReferenceText>
                  </ReferenceTextContainer>
                </div>
              : reference.marketing ?
                <div key={reference.id}>
                  <MessageTitleContainer>
                    <ReferenceTitle>{reference.marketing.title}</ReferenceTitle>
                  </MessageTitleContainer>
                  <ReferenceTextContainer>
                    <ReferenceText>{reference.marketing.text}</ReferenceText>
                  </ReferenceTextContainer>
                </div>
              : reference.web ?
                <div key={reference.id}>
                  <MessageTitleContainer>
                    <ReferenceTitle>{reference.title}</ReferenceTitle>
                  </MessageTitleContainer>
                  <ReferenceTextContainer>
                    <ReferenceText>{reference.web.text}</ReferenceText>
                    <ReferenceText>Retrieved from: {reference.web.url}</ReferenceText>
                  </ReferenceTextContainer>
                </div>
              : reference.file ?
                <div key={reference.id}>
                  <MessageTitleContainer>
                    <ReferenceTitle>{reference.file.title}</ReferenceTitle>
                  </MessageTitleContainer>
                  <ReferenceTextContainer>
                    <ReferenceText>{reference.file.text}</ReferenceText>
                  </ReferenceTextContainer>
                </div>
              : reference.product ?
                <div key={reference.id}>
                  <MessageTitleContainer>
                    <ReferenceTitle>{reference.product.name} &nbsp;{reference.product.price} {reference.product.currency}</ReferenceTitle>
                  </MessageTitleContainer>
                  <ReferenceTextContainer>
                    <ReferenceText>{reference.product.description}</ReferenceText>
                    <br />
                    <ReferenceText>Specifications: {reference.product.specifications}</ReferenceText>
                    <br />
                    <ReferenceText>Retrieved from: {reference.product.url}</ReferenceText>
                  </ReferenceTextContainer>
                </div>
              : reference.custom_data ?
                <div key={reference.id}>
                  <MessageTitleContainer>
                    <ReferenceTitle>{reference.title}</ReferenceTitle>
                  </MessageTitleContainer>
                  <ReferenceTextContainer>
                    {Object.entries(reference.custom_data.obj).map(([key, value], index) => (
                      value ? <ReferenceText key={index}>{key}: {String(value)}</ReferenceText> : null
                    ))}
                  </ReferenceTextContainer>
                </div>
              : <></>
            ))
          :
            <div>
              <h2>Inga relevanta källor tillgängliga</h2>
            </div>
          }
        </div>
      </ReferencePopupContainer>
    </PopupOverlay>
  );
}

export default ReferencePopup;