import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";
import { Marketing } from "../Types";

export const useMarketingList = () => {
  return useQuery<Marketing[], Error>({
    queryKey: ["marketing", "all"],
    queryFn: (): Promise<Marketing[]> => ax.get('/marketing/all/').then(res => res.data),
    refetchOnReconnect: "always",
    refetchInterval: 1000 * 60 * 3, //hur ofta behöver marketinglistan uppdateras?
  });
};