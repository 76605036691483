import React from 'react';
import { 
  AISection,
  AssistantHeader,
  AssistantSection, 
  ConversationSection, 
  CopilotChatItem, 
  ChatProfileImgContainer, 
  CopilotProfileImg, 
  CopilotChatItemText, 
  CopilotInputSection, 
  CopilotInput,
  SendIconButton, 
} from '../../MarketingPage/MarketingPage-styling';
import { BlinkingSpan, CopilotImgAnimation, InfoIcon } from '../../UserPage/UserPage-styling';
import MarkdownRenderer from '../../Components/MarkdownRenderer';
import { useCurrentUser } from '../../Queries/useCurrentUser';
import { FaPaperPlane } from 'react-icons/fa6';
import { useSelectedFeedback } from '../../Queries/useSelectedFeedback';
import { useAssistantConfigForModule } from '../../Queries/useAssistantConfigForModule';
import { FaInfoCircle } from 'react-icons/fa';
import { LightTooltip } from '../../UserPage/UserPage';
import { useUserMeta } from '../../Queries/useUserMeta';
import { SearchResult } from '../../Types';



type Props = {
  inputText: string;
  handleTextareaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleEnterSend: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  conversationRef: React.RefObject<HTMLDivElement>;
  handleSend: () => void;
  copilotChat: string[];
  answerLoading: boolean[];
  messageReferences: SearchResult[][];
  handleInfoIconClick: (index: number) => void;
};

const FeedbackCopilot = (props: Props) => {
  const { data: userMeta } = useUserMeta();
  const { selectedFeedback } = useSelectedFeedback();
  const { data: assistantConfig, ...assistantConfigQuery } = useAssistantConfigForModule('feedback');



  return (
    <AssistantSection>
      { selectedFeedback?.feedback_source_type_name === 'external_chatbot' ?
        <div style={{fontSize: '1.3rem', color: '#f0f0f0', margin: '10px'}} >
          AI-assistenten är inte tillgänglig för kundchatbotens chattar eftersom du inte kan redigera dessa, 
          men här till vänster kan du se vad kunderna frågar och hur kundchatboten svarar.
        </div>
      : !selectedFeedback ?
        <>
          <AssistantHeader>AI-assistenten</AssistantHeader>
          <AISection>
            <ConversationSection ref={props.conversationRef} >
              <CopilotChatItem $isCopilot={true} >
                <ChatProfileImgContainer $isCopilot={true} >
                  {assistantConfig?.logo_url ? (
                    <CopilotProfileImg src={assistantConfig.logo_url} alt="AI"/>
                  ) : (
                    "AI"
                  )}
                </ChatProfileImgContainer>
                <CopilotChatItemText>
                  Välj en feedback för att starta en chatt med AI-assistenten.
                </CopilotChatItemText>
              </CopilotChatItem>
            </ConversationSection>
          </AISection>
        </>
      :
        <>
          <AssistantHeader>AI-assistenten</AssistantHeader>
          <AISection>
            <ConversationSection ref={props.conversationRef} >
              {props.copilotChat.map((item, index)=> {
                if (index % 2 === 0) { // Check if index is even
                  return (
                    <CopilotChatItem key={index} $isCopilot={true} >
                      {props.answerLoading[index/2] &&
                        <CopilotImgAnimation style={{height: '47px', width: '47px', top: '28px', left: '28px'}} />
                      }
                    <ChatProfileImgContainer $isCopilot={true} >
                      {assistantConfig?.logo_url ? (
                        <CopilotProfileImg src={assistantConfig.logo_url} alt="AI"/>
                      ) : (
                        "AI"
                      )}
                    </ChatProfileImgContainer>
                    {props.answerLoading[index/2] &&
                      <MarkdownRenderer markdownText={item} isStreaming={true} fontSize={16} />
                    }
                    {!props.answerLoading[index/2] &&
                      <MarkdownRenderer markdownText={item} isStreaming={false} fontSize={16} />
                    }
                    {index > 0 && props.messageReferences[(index/2)-1] && props.messageReferences[(index/2)-1].length > 0 && !props.answerLoading[index/2] ?
                          <LightTooltip 
                          title={`Se ${props.messageReferences[(index/2)-1].length} källor som hjälpte till att svara på din fråga`}
                          >
                            <InfoIcon onClick={() => {
                              props.handleInfoIconClick((index/2)-1);
                            }}>
                              <FaInfoCircle />
                            </InfoIcon>
                          </LightTooltip>
                          // : index !== 0 && messageReferences[(index/2)-1] && !answerLoading[index/2] ?
                          // <LightTooltip 
                          //   title={`Inga relevanta källor tillgängliga`}
                          // >
                          //   <InfoIcon>
                          //     <FaInfoCircle />
                          //   </InfoIcon>
                          // </LightTooltip>
                          :
                          null
                        }
                  </CopilotChatItem>
                )
              } else
                return (
                  <CopilotChatItem key={index} $isCopilot={false} >
                    <ChatProfileImgContainer $isCopilot={false} >
                      {userMeta?.first_name.charAt(0)}
                    </ChatProfileImgContainer>
                    <CopilotChatItemText>{item}</CopilotChatItemText>
                  </CopilotChatItem>
                )
              })}
            </ConversationSection>
            <CopilotInputSection>
              <SendIconButton onClick={props.handleSend}>
                <FaPaperPlane />
              </SendIconButton>
              <CopilotInput
                id='feedbackCopilotInputId'
                value={props.inputText}
                onChange={props.handleTextareaChange}
                placeholder="Skriv din fråga här..."
                rows={1}
                onKeyDown={props.handleEnterSend}
              />
            </CopilotInputSection>

          </AISection>
        </>
      }
    </AssistantSection>
  );
};
export default FeedbackCopilot;