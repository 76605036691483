import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ExternalChatbotContainer, ExternalChatbotCustomerMessage, ExternalChatbotFooter, ExternalChatbotHeader, ExternalChatbotHeaderButtonSection, ExternalChatbotHeaderLogo, ExternalChatbotHeaderLogoSection, ExternalChatbotHeaderTitle, ExternalChatbotInput, ExternalChatbotMessage, ExternalChatbotMessageContainer, ExternalChatbotMessagerAvatar, ExternalChatbotMessagesSection, ExternalChatbotMinimizeButton, ExternalChatbotSendButton } from './ExternalChatbot-styling';
import { FaPaperPlane, FaWindowMinimize, FaArrowRotateRight } from 'react-icons/fa6';
import { useQueryClient } from '@tanstack/react-query';
import { ax } from '../Utils';
import { isBrightColor } from '../Functions/isBrightColor';
import ExternalMarkdown from './ExternalMarkdown';
import { useChatbotSettings } from '../Queries/useChatbotSettings';
import { useAssistantChat } from '../Queries/useAssistantChat';

declare global {
  interface Window {
    zoid: any;
    xprops: any;
  }
}


const icaLogoLink = 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/ICA_logo.svg/1200px-ICA_logo.svg.png';


const ZoidExternalChatbot = () => {
  const module = 'external_chatbot';
  // const { messages, error, isLoading, addMessage } = useChat("ChatId");
  const [inputText, setInputText] = useState<string>('');
  const [parentUrl, setParentUrl] = useState<string>('');
  const [refreshTriggered, setRefreshTriggered] = useState<boolean>(false);
  const conversationRef = useRef<HTMLDivElement>(null);


  const [textColor, setTextColor] = useState<string>('#ffffff');

  const [clientKey, setClientKey] = useState<string>('');
  
  const tokenFetched = useRef(false);

  //set first assistantChat message to the company greeting

  const queryClient = useQueryClient();

  const { chatbotSettings, updateChatbotSettings } = useChatbotSettings();
  const {assistantChat, generateAnswer, streamWelcomeMessage, addMessage, clearChat} = useAssistantChat(module, undefined);

  
  // useEffect(() => { //Behåll denna version eftersom origin-checken kanske är lite för hård
  //   console.log('ZoidExternalChatbot mounted');

  //   // Define the Zoid component in the child
  //   const Chatbot = window.zoid.create({
  //     tag: 'chatbot-widget',
  //     props: {
  //       clientKey: {
  //         type: 'string',
  //         required: true,
  //       },
  //       parentUrl: {
  //         type: 'string',
  //         required: false,
  //       },
  //       onLoad: {
  //         type: 'function',
  //         required: false,
  //       },
  //       // onHide: {
  //       //   type: 'function',
  //       //   required: false,
  //       // },
  //     },
  //     //allowedParentDomains: ['https://ragge.ai'],
  //   });

  //   const xprops = window.xprops || Chatbot.xprops;

  //   if (xprops) {
  //     const { clientKey, parentUrl, onLoad } = xprops;
  //     // console.log('Client Key from xprops:', clientKey);
  //     // console.log('Parent URL from xprops:', parentUrl);
  //     setParentUrl(parentUrl);
  //     setClientKey(clientKey);

  //     // Notify Zoid that the component has loaded
  //     if (typeof onLoad === 'function') {
  //       onLoad();
  //     } else {
  //       console.warn('onLoad is not a function');
  //     }

  //     if (!tokenFetched.current) {
  //       ax.post(`/external_chatbot/get_token_and_settings?origin_url=${parentUrl}`, {}, { 
  //         headers: { 'api-key': clientKey }
  //       }).then((response) => {
  //         // console.log('response', response.data);
  //         if (response.status === 200) {
  //           tokenFetched.current = true;
  //           window.parent.postMessage({ type: 'TOKEN_FETCHED' }, parentUrl);
  //           console.log(response.data);
  //           updateChatbotSettings(response.data);
  //         }
  //       });
        
  //     };

  //   } else {
  //     console.error('xprops is undefined');
  //   }

  //   function handleMessage(event: MessageEvent) {
  //     if (event.data && event.data.type === 'toggleOpen') {
  //       if (assistantChat.length === 1 && assistantChat[0] === '') {
  //         streamWelcomeMessage(undefined);
  //       }
  //     }
  //   }

  //   window.addEventListener('message', handleMessage);

  //   // Clean up the event listener on unmount
  //   return () => {
  //     window.removeEventListener('message', handleMessage);
  //   };
  // }, []);

  const handleMessage = useCallback((event: MessageEvent) => {
    console.log('handleMessage', event.origin, chatbotSettings);
    if (event.origin !== chatbotSettings?.valid_domains?.[0]) {
      // Ignore messages from unknown origins
      return;
    }
    if (event.data && event.data.type === 'toggleOpen') {
      if (assistantChat.length === 1 && assistantChat[0] === '') {
        streamWelcomeMessage(undefined);
      }
    }
  }, [chatbotSettings, assistantChat, streamWelcomeMessage]);
  
  useEffect(() => {
    console.log('ZoidExternalChatbot mounted');

    // Define the Zoid component in the child
    const Chatbot = window.zoid.create({
      tag: 'chatbot-widget',
      props: {
        clientKey: {
          type: 'string',
          required: true,
        },
        parentUrl: {
          type: 'string',
          required: false,
        },
        onLoad: {
          type: 'function',
          required: false,
        },
        // onHide: {
        //   type: 'function',
        //   required: false,
        // },
      },
      //allowedParentDomains: ['https://ragge.ai'],
    });

    const xprops = window.xprops || Chatbot.xprops;

    if (xprops) {
      const { clientKey, parentUrl, onLoad } = xprops;
      // console.log('Client Key from xprops:', clientKey);
      // console.log('Parent URL from xprops:', parentUrl);
      setParentUrl(parentUrl);
      setClientKey(clientKey);

      // Notify Zoid that the component has loaded
      if (typeof onLoad === 'function') {
        onLoad();
      } else {
        console.warn('onLoad is not a function');
      }
      let fetchAttempt = 1;
      const MAX_RETRY_ATTEMPTS = 3;

      if (!tokenFetched.current) {
        const fetchTokenAndSettings = async () => {
          try {
            const response = await ax.post(`/external_chatbot/get_token_and_settings?origin_url=${parentUrl}`, {}, { 
              headers: { 'api-key': clientKey }
            });
            
            if (response.status === 200) {
              tokenFetched.current = true;
              window.parent.postMessage({ type: 'TOKEN_FETCHED' }, parentUrl);
              console.log(response.data);
              updateChatbotSettings(response.data);
            }
          } catch (error) {
            console.log('error: ', fetchAttempt);
            if (fetchAttempt < MAX_RETRY_ATTEMPTS) {
              fetchAttempt++;
              // Retry after 2 seconds
              setTimeout(fetchTokenAndSettings, 2000);
            } else {
              // If all retries failed, notify parent to disable the button
              //window.parent.postMessage({ type: 'TOKEN_FETCH_FAILED' }, parentUrl);
              console.log('Token fetch failed');
            }
          }
        };
      
        fetchTokenAndSettings();
      };

      // if (!tokenFetched.current) {
      //   ax.post(`/external_chatbot/get_token_and_settings?origin_url=${parentUrl}`, {}, { 
      //     headers: { 'api-key': clientKey }
      //   }).then((response) => {
      //     // console.log('response', response.data);
      //     if (response.status === 200) {
      //       tokenFetched.current = true;
      //       window.parent.postMessage({ type: 'TOKEN_FETCHED' }, parentUrl);
      //       console.log(response.data);
      //       updateChatbotSettings(response.data);
      //     }
      //   });
        
      // };

    } else {
      console.error('xprops is undefined');
    }

  }, []);

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    // Add event listener for link clicks
    const handleLinkClick = (event: MessageEvent) => {
      if (event.data && event.data.type === 'OPEN_LINK') {
        window.parent.postMessage({ 
          type: 'OPEN_LINK', 
          url: event.data.url 
        }, parentUrl);
      }
    };
  
    window.addEventListener('message', handleLinkClick);
  
    return () => {
      window.removeEventListener('message', handleLinkClick);
    };
  }, [parentUrl]);
  
  useEffect(() => {
    if (chatbotSettings?.theme) {
      const isBright = isBrightColor(chatbotSettings.theme);
      setTextColor(isBright ? '#000000' : '#FFFFFF');
    }
  }, [chatbotSettings]);

  useEffect(() => {
    scrollToBottom();
    if (assistantChat[0] === "" && refreshTriggered) {
      setRefreshTriggered(false);
      streamWelcomeMessage(undefined);
    }
  }, [assistantChat]);

  const handleSend = () => {
    if (inputText === ''){
      return;
    }
    addMessage(inputText);
    generateAnswer({text: inputText, module_name: module});
    setInputText('');
  };
  
  const handleEnterSend = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleTextareaChange = (e: { target: { value: React.SetStateAction<string>; style: { height: string; }; scrollHeight: number; }; }) => {
    setInputText(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight - 40 > 200 ? 200 : e.target.scrollHeight - 40) + 'px';
  };
  
  const scrollToBottom = () => {
    if (conversationRef.current && assistantChat.length !== 0) {
      const lastMessage = conversationRef.current.lastChild as HTMLElement;
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleMinimize = () => {
    if (window.parent) {
      window.parent.postMessage({ type: 'MINIMIZE_CHATBOT' }, parentUrl); // Send a message to the parent to minimize
    } else {
      console.error('Parent window is not available for messaging');
    }
  };

  const handleRefresh = () => {
    setRefreshTriggered(true);
    clearChat();
    setInputText('');
  }

  return (
    <ExternalChatbotContainer>
      <ExternalChatbotHeader style={{ backgroundColor: chatbotSettings?.theme || '#0f86a1', color: textColor }}>
        <ExternalChatbotHeaderLogoSection>
          <ExternalChatbotHeaderLogo src={chatbotSettings?.assistant_logo_url} />
          {/* alternativt ragge-logo om man inte har någon logo */}
          <ExternalChatbotHeaderTitle>{chatbotSettings?.assistant_name || 'AI-hjälprobot'}</ExternalChatbotHeaderTitle>
        </ExternalChatbotHeaderLogoSection>
        <ExternalChatbotHeaderButtonSection>
          <div style={{ marginTop: '10px', marginRight: '5px' }}>
            <ExternalChatbotMinimizeButton style={{ color: textColor }} onClick={handleRefresh}>
              <FaArrowRotateRight />
            </ExternalChatbotMinimizeButton>
          </div>
          <ExternalChatbotMinimizeButton style={{ color: textColor }} onClick={handleMinimize}>
            <FaWindowMinimize />
          </ExternalChatbotMinimizeButton>
        </ExternalChatbotHeaderButtonSection>
      </ExternalChatbotHeader>
      <ExternalChatbotMessagesSection ref={conversationRef} >
        {/*chat.isLoading ? <p>Loading...</p>
        : chat.isError ? <p>Error: {chat.error.message}</p>
        : chat.data ? chat.data.map((item, index)=> {
            if (index % 2 === 0) { // Check if index is even
              return (
                <ExternalChatbotMessageContainer key={index}>
                  <ExternalChatbotMessagerAvatar src="https://via.placeholder.com/30x30" />
                  <ExternalChatbotMessage >
                    {item}
                  </ExternalChatbotMessage>
                </ExternalChatbotMessageContainer>
              )
            } else {
              return(
                <ExternalChatbotMessageContainer key={index}>
                  <ExternalChatbotCustomerMessage>
                    {item}
                  </ExternalChatbotCustomerMessage>
                </ExternalChatbotMessageContainer>
              )
            }
          }) : null
      */}
      {assistantChat.map((item, index) => {
        if (index % 2 === 0) { // Check if index is even
          return (
            <ExternalChatbotMessageContainer key={index}>
              <ExternalChatbotMessagerAvatar src={chatbotSettings?.assistant_logo_url} />
              {/* alternativt ragge-logo om man inte har någon logo */}
              <ExternalChatbotMessage >
                <ExternalMarkdown markdownText={item} textColor='#000' />
              </ExternalChatbotMessage>
            </ExternalChatbotMessageContainer>
          )
        } else {
          return(
            <ExternalChatbotMessageContainer key={index}>
              <ExternalChatbotCustomerMessage>
                <ExternalMarkdown markdownText={item} textColor='#fff' />
              </ExternalChatbotCustomerMessage>
            </ExternalChatbotMessageContainer>
          )
        }
      })}


      </ExternalChatbotMessagesSection>
      <ExternalChatbotFooter>
        <ExternalChatbotInput
          placeholder="Skriv ett meddelande..."
          rows={1}
          onKeyDown={handleEnterSend}
          onChange={handleTextareaChange}
          value={inputText}
          />
        <ExternalChatbotSendButton onClick={handleSend} style={{ backgroundColor: chatbotSettings?.theme || '#0f86a1', color: textColor }}>
          <FaPaperPlane />
        </ExternalChatbotSendButton>
      </ExternalChatbotFooter>
    </ExternalChatbotContainer>
  );
}

export default ZoidExternalChatbot;