import { FaTrashCan, FaWrench } from "react-icons/fa6";
import { Inbox } from "../../../Types";
import { AdminListContainer, AdminListItem, DeleteButton, ListConfigureButton, RefreshButton } from "../../AdminPage-styling";
import { useFeedbackSourceList } from "../../../Queries/useFeedbackSourceList";
import Swal from "sweetalert2";
import { FaSync } from "react-icons/fa";
import { ax, formatDate } from "../../../Utils";
import { useQueryClient } from "@tanstack/react-query";
import '../../../CSS/swal.css';
import { useCurrentUser } from "../../../Queries/useCurrentUser";
import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import Table from "../Table";


const EmailInboxList = () => {

  const queryClient = useQueryClient();

  const { data: currentUser } = useCurrentUser();
  const { data: feedbackSourceList } = useFeedbackSourceList(currentUser?.unit_id, 'email');


  const handleDeleteInboxPopup = (inboxId: number, inboxName: string, inboxEmail: string) => {
    console.log(feedbackSourceList);
    Swal.fire({
      title: `Är du säker på att du vill ta bort inkorgen ${inboxName} (${inboxEmail})?`,
      text: "Du kan inte ångra beslutet!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#888",
      confirmButtonText: "Ta bort inkorg",
      cancelButtonText: "Avbryt"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteInbox(inboxId);
      }
    });
  };

  const deleteInbox = async (inboxId: number) => {
    try {
      await ax.delete(`/feedback_source/${inboxId}`)
        .then(() => {
          queryClient.setQueryData(['feedback_source', 'all', 'list', {unit_id: currentUser?.unit_id}, { feedback_source_type_name: 'email' }], (oldData: Inbox[]) => {
            return oldData.filter((inbox) => inbox.id !== inboxId);
          });
          Swal.fire({
            title: "Inkorgen borttagen!",
            icon: "success"
          });
        });
    } catch (error) {
      Swal.fire({
        title: "Borttagning misslyckades!",
        text: "Försök igen senare",
        icon: "error"
      });
    }
  };

  const handleConfigureInbox = (inbox: Inbox) => {
    Swal.fire({
      title: "Konfigurera inkorg",
      input: 'text',
      inputLabel: 'Namnge inkorg',
      inputValue: inbox.name,
      inputPlaceholder: 'Namn',
      showCancelButton: true,
      confirmButtonText: "Uppdatera inkorg",
      cancelButtonText: "Avbryt",
      customClass: {
        inputLabel: 'swal2-input-label-custom'
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        updateInbox(inbox, result.value);
      }
      else if (result.isConfirmed && !result.value) {
        Swal.fire({
          title: "Inkorgen behöver ett namn!",
          icon: "error"
        });
      }
    });
  };

  const updateInbox = async (inbox: Inbox, inboxName: string) => {
    console.log('inboxName', inboxName, 'inboxId', inbox.id);
    const requestBody = {
      "id": inbox.id,
      "name": inboxName
    };
    try {
      await ax.patch(`/feedback_source?set_nulls=false`, requestBody)//Gör till mutation
        .then((response) => {
          queryClient.setQueryData(['feedback_source', 'all', 'list', {unit_id: currentUser?.unit_id}, { feedback_source_type_name: 'email' }], (oldData: Inbox[]) => {
            return oldData.map((i) => i.id === inbox.id ? response.data : i);
          });
          Swal.fire({
            title: "Inkorg uppdaterad!",
            icon: "success"
          });
        });
    } catch (error) {
      Swal.fire({
        title: "Uppdatering misslyckades!",
        text: "Försök igen senare",
        icon: "error"
      });
    }
  };

  const handleSyncInbox = async (inboxId: number) => {
    if (inboxId) {
      try {
        await ax.post(`/feedback_source/refresh/?feedback_source_id=${inboxId}`)
          .then(() => {
            queryClient.invalidateQueries({ queryKey: ['feedback_source', 'all', 'list', {unit_id: currentUser?.unit_id}, { feedback_source_type_name: 'email' }] });
          });
      } catch (error) {
        Swal.fire({
          title: "Synkronisering misslyckades!",
          text: "Försök igen senare",
          icon: "error"
        });
      }
    }
    else {
      Swal.fire({
        title: "Inkorg hittades inte",
        text: "Försök igen senare",
        icon: "error"
      });
    }
  };

  const handleActivateInbox = async (inboxId: number, activated: boolean) => {
    console.log('inboxId', inboxId, 'activated', activated);
    const requestBody = {
      "id": inboxId,
      "activated": activated
    };
    try {
      await ax.patch(`/feedback_source`, requestBody)
        .then((response) => {
          queryClient.setQueryData(['feedback_source', 'all', 'list', {unit_id: currentUser?.unit_id}, { feedback_source_type_name: 'email' }], (oldData: Inbox[]) => {
            return oldData.map((i) => i.id === inboxId ? response.data : i);
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const columns = useMemo<ColumnDef<Inbox, any>[]>(
    () => [
      {
        accessorFn: row => row.config.email,
        id: 'email',
        header: () => <span>E-postadress</span>,
        cell: info => {
          return (
            <>
              {info.getValue()}
            </>
          )
        },
        meta: {
          filterVariant: 'text',
        },
      },
      {
        accessorKey: 'name',
        header: () => <span>Namn</span>,
        cell: info => {
          return (
            <>
              {info.getValue()}
            </>
          )
        },
        meta: {
          filterVariant: 'text',
        },
      },
      {
        accessorKey: 'last_refreshed_at',
        header: () => <span>Senast synkad</span>,
        cell: info => {
          return (
            <>
              {info.getValue() ? formatDate(info.getValue()) : 'Ej synkad'}
              {info.row.original.activated ? 
                <RefreshButton onClick={() => handleSyncInbox(info.row.original.id)}><FaSync /></RefreshButton> 
              : null}
            </>
          )
        },
      },
      {
        accessorFn: row => row.activated,
        id: 'activated',
        header: () => <span>Aktiverad</span>,
        cell: info => {
          return (
            <input 
              type="checkbox" 
              checked={info.getValue()} 
              onChange={(e) => handleActivateInbox(info.row.original.id, e.target.checked)} 
            />
          )
        },
      },
      {
        accessorKey: 'edit_delete',
        enableSorting: false,
        header: () => <></>,
        cell: info => {
          return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <ListConfigureButton onClick={() => handleConfigureInbox(info.row.original)}>
                <FaWrench />
              </ListConfigureButton>
              <DeleteButton onClick={() => handleDeleteInboxPopup(info.row.original.id, info.row.original.name, info.row.original.config.email)}>
                <FaTrashCan />
              </DeleteButton>
            </div>
          )
        },
      }
    ],
    [handleConfigureInbox, handleDeleteInboxPopup, handleSyncInbox, handleActivateInbox]
  );


  return (
    <>
      <Table 
        columns={columns} 
        data={feedbackSourceList ?? []} 
        columnWidths={{
          first: '40%',
          last: '1%',
          secondToLast: '10%',
          middle: '20%',
        }}
      />
      {/* <AdminListContainer>
        {feedbackSourceList?.length === 0 ? (
          <AdminListItem>Hittade inga inkorgar</AdminListItem>
        ) : (
          <>
            <AdminListItem>
              <div style={{fontWeight: '500', fontSize: '1.2rem'}}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Namn | E-postadress | Senast synkad | Aktiverad
            </div>
          </AdminListItem>
          {feedbackSourceList?.map((inbox: Inbox) => (
            <AdminListItem key={inbox.id}>
              <DeleteButton onClick={() => handleDeleteInboxPopup(inbox.id, inbox.name, inbox.config.email)}>
                <FaTrashCan />
              </DeleteButton>
              <ListConfigureButton onClick={() => handleConfigureInbox(inbox)}>
                <FaWrench />
              </ListConfigureButton>
              {inbox.name} | {inbox.config.email} | Senast synkad: {inbox.last_refreshed_at ? formatDate(inbox.last_refreshed_at) : 'Ej synkad'}&nbsp;&nbsp;
              <RefreshButton onClick={() => handleSyncInbox(inbox.id)}>
                <FaSync />
              </RefreshButton>
              <div style={{display: 'inline-flex', alignItems: 'center'}}>
                &nbsp;&nbsp;| Aktiv:&nbsp;&nbsp;
                <input type="checkbox" checked={inbox.activated} onChange={(e) => handleActivateInbox(inbox.id, e.target.checked)} />
              </div>
            </AdminListItem>
          ))}
          </>
      )}
      </AdminListContainer> */}
    </>
  );
};

export default EmailInboxList;