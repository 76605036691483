import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";

export const useFeedbackSourceList = (unitId: string | undefined, feedbackSourceTypeName: string) => {
  return useQuery({
    queryKey: ['feedback_source', 'all', 'list', {unit_id: unitId}, { feedback_source_type_name: feedbackSourceTypeName }],
    queryFn: () => ax.get(`/feedback_source/all/list/?unit_id=${unitId}&feedback_source_type_name=${feedbackSourceTypeName}`).then(res => res.data),
    enabled: !!feedbackSourceTypeName && !!unitId,
    refetchOnReconnect: true,
  });
};