import { useQuery } from "@tanstack/react-query";
import { UserUnitAccess } from "../Types";
import { ax } from "../Utils";



export const useUserUnitAccesses = (unitId: string | undefined) => {
  return useQuery<UserUnitAccess[], Error>({
    queryKey: ['user_unit_access', 'all', 'list', {unit_id: unitId}],
    queryFn: (): Promise<UserUnitAccess[]> => ax.get(`/user_unit_access/all/list/?unit_id=${unitId}`).then(res => res.data),
    enabled: !!unitId,
    refetchOnReconnect: true,
  });
}