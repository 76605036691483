import { useQuery } from "@tanstack/react-query";
import { AssistantConfig } from "../Types";
import { ax } from "../Utils";



export const useAssistantConfigForModule = (moduleName: string) => {
  return useQuery<AssistantConfig, Error>({
    queryKey: ['assistant_config', 'for_module', { module_name: moduleName }],
    queryFn: (): Promise<AssistantConfig> => ax.get(`/assistant_config/for_module/?module_name=${moduleName}`).then(res => res.data),
    enabled: !!moduleName,
    refetchOnReconnect: true,
  });
}