import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";
import { AssistantConfig } from "../Types";



export const useAssistantConfigs = (unitId: string | undefined) => {
  return useQuery<AssistantConfig[], Error>({
    queryKey: ['assistant_config', 'all', 'list', { unit_id: unitId }],
    queryFn: (): Promise<AssistantConfig[]> => ax.get(`/assistant_config/all/list/?config_type=internal`).then(res => res.data),
    enabled: !!unitId,
    refetchOnReconnect: true,
  });
}