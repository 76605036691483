import { AdminButton, AdminSettingContainer, AdminSettingSelectBox, AdminSettingText, RedAdminButton } from "../../AdminPage-styling";
import AppearanceConfig from "./AppearanceConfig";
import BehaviorConfig from "./BehaviorConfig";
import { useNavigate } from "react-router-dom";
import { useChatbotConfigList } from "../../../Queries/useChatbotConfigList";
import { useSelectedChatbot } from "../../../Queries/useSelectedChatbot";
import BasicInfoConfig from "./BasicInfoConfig";
import Swal from "sweetalert2";
import { ax } from "../../../Utils";
import { useQueryClient } from "@tanstack/react-query";
import { ChatbotConfig } from "../../../Types";
import { useShowChatbotConfig } from "../../../Queries/useShowChatbotConfig";
import { useState } from "react";


const ChatbotConfigTab = () => {

  
  
  const {data: chatbotConfigList} = useChatbotConfigList();
  const {selectedChatbot, updateSelectedChatbot} = useSelectedChatbot();
  const {showChatbotConfig, updateShowChatbotConfig} = useShowChatbotConfig();
  
  const [localSelectedChatbotId, setLocalSelectedChatbotId] = useState<string | null>(selectedChatbot?.id || (showChatbotConfig ? '' : 'none'));

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const handleTestChatbot = () => {
    navigate('/external-chatbot-test');
  }

  const handleDeleteChatbot = () => {
    if (selectedChatbot) {
      Swal.fire({
        title: 'Ta bort chatbot?',
        text: 'Är du säker på att du vill ta bort chatboten?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#a12a0f',
        cancelButtonText: 'Avbryt',
        confirmButtonText: 'Ta bort',
      }).then((result) => {
        if (result.isConfirmed) {
          deleteChatbot(selectedChatbot.id, selectedChatbot.assistant_config_id);
        }
      });
    }
  }

  const deleteChatbot = (chatbot_config_id: string, assistant_config_id: string | null) => {
    ax.delete(`/chatbot_config/${chatbot_config_id}`)
      .then(() => {
        if (assistant_config_id) {
          ax.delete(`/assistant_config/${assistant_config_id}`);
        }
        updateSelectedChatbot(null);
        updateShowChatbotConfig(false);
        queryClient.setQueryData(['chatbot_config', 'all'], (oldData: ChatbotConfig[]) => {
          return oldData.filter(chatbot => chatbot.id !== chatbot_config_id);
        });
      });
  }

  const handleSelectChange = (e: { target: { value: string; }; }) => {
    const value = e.target.value;
    setLocalSelectedChatbotId(value);

    if (value === 'none') {
      updateSelectedChatbot(null);
      updateShowChatbotConfig(false);
    } else {
      const selected = chatbotConfigList?.find(chatbot => chatbot.id === value);
      updateSelectedChatbot(selected || null);
      updateShowChatbotConfig(true);
    }
  };


  return (
    <AdminSettingContainer>
      <AdminSettingText>Välj chatbot att redigera och testa</AdminSettingText>
      <AdminSettingSelectBox 
        style={{fontSize: '1.2rem'}}
        value={localSelectedChatbotId || ''}
        onChange={handleSelectChange}
      >
        <option style={{color: '#666', fontStyle: 'italic'}} value="none">Välj chatbot</option>
        <option style={{ fontWeight: 'bold'}} value="">Ny chatbot</option>
        {chatbotConfigList?.map((chatbot) => (
          <option key={chatbot.id} value={chatbot.id}>
            {chatbot.config_name || 'Namnlös chatbot'}
          </option>
        ))}
      </AdminSettingSelectBox>
      {selectedChatbot && (
        <>
          <AdminButton style={{marginTop: '1rem'}} 
            onClick={handleTestChatbot}
          >Testa chatbot</AdminButton>
          <RedAdminButton
            onClick={handleDeleteChatbot}
          >Ta bort chatbot</RedAdminButton>
        </>
      )}
      {showChatbotConfig && (
        <>
          <BasicInfoConfig />
          <AppearanceConfig />
          <BehaviorConfig />
        </>
      )}
    </AdminSettingContainer>
  )
}

export default ChatbotConfigTab;