import {
  ErrandsListSection,
  ErrandsHeader,
  ErrandsListContainer,
  ErrandCardStyling,
  ErrandCardTitle,
  ErrandCardText,
  ErrandCardDate,
} from "../../ErrandsPage/ErrandsPage-styling"
import { Feedback, FeedbackType } from '../../Types';
import { formatDateTime } from '../../Functions/formatDateTime';
import { useFeedbackList } from '../../Queries/useFeedbackList';
import { useSelectedFeedback } from '../../Queries/useSelectedFeedback';
import { FeedbackListSearch, FeedbackListSearchContainer, FeedbackListSearchIconButton, FeedbackSelectHeader } from "../Feedback-styling";
import { FaSearch } from "react-icons/fa";
import { useFeedbackTypeFilter } from "../../Queries/useFeedbackTypeFilter";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

type FeedbackCardProps = {
  feedback: Feedback;
  lastClickedId: string | null;
};

const FeedbackCard = (props: FeedbackCardProps) => {
  const { selectedFeedback, updateSelectedFeedback } = useSelectedFeedback();
  
  return (
    <ErrandCardStyling onClick={() => {
      console.log('feedback: ', props.feedback);
      updateSelectedFeedback(props.feedback);
    }}
      selected={props.lastClickedId === props.feedback.id}
    >
      <ErrandCardTitle>{props.feedback.title}</ErrandCardTitle>
      <ErrandCardText>{props.feedback.last_message_content}</ErrandCardText>
      <ErrandCardDate>
        {formatDateTime(props.feedback.last_message_sent_at)}
      </ErrandCardDate>
    </ErrandCardStyling>
  );
};

type Props = {
  lastClickedId: string | null;
};

const FeedbackList = (props: Props) => {
  const [searchText, setSearchText] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { feedbackTypeFilter, updateFeedbackTypeFilter } = useFeedbackTypeFilter();
  const { data: feedbacks, ...feedbacksQuery } = useFeedbackList(feedbackTypeFilter || "", searchText);
  const { selectedFeedback, updateSelectedFeedback } = useSelectedFeedback();

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id && (!selectedFeedback || selectedFeedback.id !== id)) {
      // Find feedback with matching ID from the list
      const feedback = feedbacks?.find(f => f.id === id);
      if (feedback) {
        updateSelectedFeedback(feedback);
      }
    }
  }, [id]);

  useEffect(() => {
    if (selectedFeedback && selectedFeedback.id !== id) {
      navigate(`/feedback/${selectedFeedback.id}`);
    } else if (!selectedFeedback && id) {
      navigate('/feedback');
    }
  }, [selectedFeedback]);

  const handleEnterSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setSearchText(event.currentTarget.value);
      console.log(searchText);
    }
  };

  useEffect(() => {
    feedbacksQuery.refetch(); //TODO: KANSKE refetcha inte på feedback type, fixa filtrering i frontend för att minska antal requests
  }, [feedbackTypeFilter, searchText]); //ej viktigt

  return (
    <ErrandsListSection>
      <FeedbackSelectHeader 
        value={feedbackTypeFilter || ""}
        onChange={(e: { target: { value: string }; }) => {
          updateFeedbackTypeFilter(e.target.value as FeedbackType);
        }}
      >
        <option value="">All feedback</option>
        <option value="email">E-post</option>
        <option value="review">Recensioner</option>
        <option value="external_chatbot">Kundchatbot</option>
      </FeedbackSelectHeader>
      <FeedbackListSearchContainer>
        <FeedbackListSearchIconButton
          onClick={() => {
            if (searchInputRef.current) {
              setSearchText(searchInputRef.current.value);
            }
          }}
        >
          <FaSearch />
        </FeedbackListSearchIconButton>
        <FeedbackListSearch 
          ref={searchInputRef}
          placeholder="Sök..."
          onKeyDown={handleEnterSearch}
        />

      </FeedbackListSearchContainer>
      <ErrandsListContainer>
        {feedbacks?.map((feedback, index) => (
          <FeedbackCard
            key={index}
            feedback={feedback}
            lastClickedId={props.lastClickedId}
          />
        ))}
      </ErrandsListContainer>
    </ErrandsListSection>
  );
};
export default FeedbackList;

