import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ax } from "../Utils";
import { AssistantConfig } from "../Types";



export const useAssistantConfigMutation = () => {
  const queryClient = useQueryClient();

  const assistantConfigMutation = useMutation({
    mutationFn: (requestBody: any) => {
      console.log('requestBody: ', requestBody);
      if (requestBody.id) {
        return ax.patch('/assistant_config', requestBody);
      } else {
        return ax.post('/assistant_config', requestBody);
      }
    },
    onSuccess: (response, variables) => {
      console.log('response: ', response);
      if (response.data.for_external_chatbot) {
        console.log('response.data.for_external_chatbot: ', response.data.for_external_chatbot);
        queryClient.setQueryData(['assistant_config', {obj_id: response.data.id}], response.data);
      } else {
        console.log('response.data.unit_id: ', response);
        queryClient.setQueryData(['assistant_config', 'all', 'list', { unit_id: response.data.unit_id }], (oldData: AssistantConfig[] = []) => {
          console.log('oldData: ', oldData);
          if (variables.id) {
            return oldData.map(config => config.id === variables.id ? response.data : config);
          }
          return [...oldData, response.data];
        });
      }
    }
  });

  return assistantConfigMutation;
}