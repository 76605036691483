import { useQuery } from '@tanstack/react-query';
import { ax } from '../Utils';
import { Email, FeedbackType, ChatbotMessage, FeedbackDataMap } from '../Types';

// export const useFeedbackData = (feedback_type: FeedbackType | undefined, feedback_id: string | undefined) => {
//   return useQuery<Email[] | ChatbotMessage[], Error>({
//     queryKey: [{feedback_type}, `feedback`, {feedback_id}],
//     queryFn: (): Promise<Email[] | ChatbotMessage[]> => ax.get(`/${feedback_type}/feedback/?feedback_id=${feedback_id}`).then(res => res.data),
//     enabled: !!feedback_type && !!feedback_id,
//     initialData: [],
//     staleTime: 0,
//   });
// };

// export const useFeedbackData = <T extends FeedbackType | undefined>(
//   feedback_type: T,
//   feedback_id: string | undefined
// ) => {
//   type Data = T extends FeedbackType ? FeedbackDataMap[T] : never[];

export const useFeedbackData = <T extends FeedbackType>(
  feedback_type: T | undefined,
  feedback_id: string | undefined
) => {
  type Data = FeedbackDataMap[T];
  console.log("useFeedbackData called with: ", feedback_type, feedback_id);

  return useQuery<Data, Error>({
    queryKey: [{ feedback_type }, `feedback`, { feedback_id }],
    queryFn: async (): Promise<Data> => {
      const res = await ax.get(`/${feedback_type}/feedback/?feedback_id=${feedback_id}`);
      return res.data;
    },
    enabled: !!feedback_type && !!feedback_id,
    initialData: [] as Data,
    staleTime: 0,
    refetchOnReconnect: true,
  });
};