import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBullhorn, FaRegComments, FaRegChartBar, FaSearch, FaUserTie, FaEnvelope, FaCog } from "react-icons/fa";
import "./IconNavigation-styling.css";
import { FaInstagram, FaRegEnvelope, FaWhatsapp, FaRobot } from "react-icons/fa6";
import { TbMessageChatbot } from "react-icons/tb";
import { useFeedbackTypeFilter } from "../Queries/useFeedbackTypeFilter";
import { FeedbackType } from "../Types";
import { RiSurveyLine } from "react-icons/ri";
import { VscFeedback } from "react-icons/vsc";
import { MdAssistant } from "react-icons/md";
import { useCurrentUserUnitAccess } from "../Queries/useCurrentUserUnitAccess";
import { useUserMeta } from "../Queries/useUserMeta";

const IconNavigation = () => {
  const { feedbackTypeFilter, updateFeedbackTypeFilter } = useFeedbackTypeFilter();
  const { data: userMeta } = useUserMeta();
  const { data: currentUserUnitAccess } = useCurrentUserUnitAccess(userMeta?.unit_id);

  const handleClick = (filter: string) => {
    updateFeedbackTypeFilter(filter as FeedbackType);
  };

  return (
    <div className="vertical-navbar">
      <Link to="/user" className="nav-item">
        <MdAssistant className="nav-icon"/>
        <span className="nav-text">Assistent</span>
      </Link>
      {currentUserUnitAccess?.roles?.feedback ?
        <>
          <Link to="/feedback" className="nav-item"
            onClick={() => updateFeedbackTypeFilter(null)}
          >
            <FaRegComments className="nav-icon"/>
            <span className="nav-text">Feedback</span>
          </Link>
          <div>
            <Link to="/feedback" className="nav-item nav-item-sub"
              onClick={() => handleClick("email")}
            >
              <FaRegEnvelope className="nav-icon nav-icon-sub"/>
              <span className="nav-text nav-text-sub">E-post</span>
            </Link>
            <Link to="/feedback" className="nav-item nav-item-sub"
              onClick={() => handleClick("external_chatbot")}
            >
              <FaRobot className="nav-icon nav-icon-sub"/>
              <span className="nav-text nav-text-sub">Kundchatbot</span>
              <span className="new-badge">Nyhet!</span>
            </Link>
            <div className="nav-item nav-item-sub disabled-nav-item" >
              <VscFeedback className="nav-icon nav-icon-sub disabled-icon"/>
              <span className="nav-text nav-text-sub">Recensioner</span>
              <span className="coming-soon-badge">Coming Soon</span>
            </div>
            <div className="nav-item nav-item-sub disabled-nav-item" >
              <RiSurveyLine className="nav-icon nav-icon-sub disabled-icon"/>
              <span className="nav-text nav-text-sub">Enkäter</span>
              <span className="coming-soon-badge">Coming Soon</span>
            </div>
            <div className="nav-item nav-item-sub disabled-nav-item" >
              <FaWhatsapp className="nav-icon nav-icon-sub disabled-icon"/>
              <span className="nav-text nav-text-sub">WhatsApp</span>
              <span className="coming-soon-badge">Coming Soon</span>
            </div>
            <div className="nav-item nav-item-sub disabled-nav-item" >
              <FaInstagram className="nav-icon nav-icon-sub disabled-icon"/>
              <span className="nav-text nav-text-sub">Instagram</span>
              <span className="coming-soon-badge">Coming Soon</span>
            </div>
            
            {/* <Link to="/feedback" className="nav-item nav-item-sub"
              onClick={() => handleClick("review")}
            >
              <VscFeedback className="nav-icon nav-icon-sub"/>
              <span className="nav-text nav-text-sub">Recensioner</span>
              <span className="new-badge">Ny!</span>
            </Link>
            <Link to="/feedback" className="nav-item nav-item-sub"
              onClick={() => handleClick("survey")}
            >
              <RiSurveyLine className="nav-icon nav-icon-sub"/>
              <span className="nav-text nav-text-sub">Formulär</span>
              <span className="new-badge">Ny!</span>
            </Link>
            <Link to="/feedback" className="nav-item nav-item-sub"
              onClick={() => handleClick("whatsapp")}
            >
              <FaWhatsapp className="nav-icon nav-icon-sub"/>
              <span className="nav-text nav-text-sub">WhatsApp</span>
              <span className="new-badge">Ny!</span>
            </Link>
            <Link to="/feedback" className="nav-item nav-item-sub"
              onClick={() => handleClick("instagram")}
            >
              <FaInstagram className="nav-icon nav-icon-sub"/>
              <span className="nav-text nav-text-sub">Instagram</span>
              <span className="new-badge">Ny!</span>
            </Link> */}
          </div>
        </>
        : 
        <>
          <div className="nav-item disabled-nav-item"
            title="Du har inte tillgång till denna funktion"
          >
            <FaRegComments className="nav-icon disabled-icon"/>
            <span className="nav-text">Feedback</span>
          </div>
          <div>
            <div className="nav-item nav-item-sub disabled-nav-item"
              title="Du har inte tillgång till denna funktion"
            >
              <FaRegEnvelope className="nav-icon nav-icon-sub disabled-icon"/>
              <span className="nav-text nav-text-sub">E-post</span>
            </div>
            <div className="nav-item nav-item-sub disabled-nav-item"
              title="Du har inte tillgång till denna funktion"
            >
              <FaRobot className="nav-icon nav-icon-sub disabled-icon"/>
              <span className="nav-text nav-text-sub">Kundchatbot</span>
              <span className="new-badge">Nyhet!</span>
            </div>
            <div className="nav-item nav-item-sub disabled-nav-item" title="Du har inte tillgång till denna funktion" >
              <VscFeedback className="nav-icon nav-icon-sub disabled-icon"/>
              <span className="nav-text nav-text-sub">Recensioner</span>
              <span className="coming-soon-badge">Coming Soon</span>
            </div>
            <div className="nav-item nav-item-sub disabled-nav-item" title="Du har inte tillgång till denna funktion" >
              <RiSurveyLine className="nav-icon nav-icon-sub disabled-icon"/>
              <span className="nav-text nav-text-sub">Enkäter</span>
              <span className="coming-soon-badge">Coming Soon</span>
            </div>
            <div className="nav-item nav-item-sub disabled-nav-item" title="Du har inte tillgång till denna funktion" >
              <FaWhatsapp className="nav-icon nav-icon-sub disabled-icon"/>
              <span className="nav-text nav-text-sub">WhatsApp</span>
              <span className="coming-soon-badge">Coming Soon</span>
            </div>
            <div className="nav-item nav-item-sub disabled-nav-item" title="Du har inte tillgång till denna funktion" >
              <FaInstagram className="nav-icon nav-icon-sub disabled-icon"/>
              <span className="nav-text nav-text-sub">Instagram</span>
              <span className="coming-soon-badge">Coming Soon</span>
            </div>
            
            {/* <Link to="/feedback" className="nav-item nav-item-sub"
              onClick={() => handleClick("review")}
            >
              <VscFeedback className="nav-icon nav-icon-sub"/>
              <span className="nav-text nav-text-sub">Recensioner</span>
              <span className="new-badge">Ny!</span>
            </Link>
            <Link to="/feedback" className="nav-item nav-item-sub"
              onClick={() => handleClick("survey")}
            >
              <RiSurveyLine className="nav-icon nav-icon-sub"/>
              <span className="nav-text nav-text-sub">Formulär</span>
              <span className="new-badge">Ny!</span>
            </Link>
            <Link to="/feedback" className="nav-item nav-item-sub"
              onClick={() => handleClick("whatsapp")}
            >
              <FaWhatsapp className="nav-icon nav-icon-sub"/>
              <span className="nav-text nav-text-sub">WhatsApp</span>
              <span className="new-badge">Ny!</span>
            </Link>
            <Link to="/feedback" className="nav-item nav-item-sub"
              onClick={() => handleClick("instagram")}
            >
              <FaInstagram className="nav-icon nav-icon-sub"/>
              <span className="nav-text nav-text-sub">Instagram</span>
              <span className="new-badge">Ny!</span>
            </Link> */}
          </div>
        </>
      }
      {currentUserUnitAccess?.roles?.marketing ?
        <Link to="/marketing" className="nav-item">
          <FaBullhorn className="nav-icon"/>
          <span className="nav-text">Marknadsföring</span>
        </Link>
        :
        <div className="nav-item disabled-nav-item"
          title="Du har inte tillgång till denna funktion"
        >
          <FaBullhorn className="nav-icon disabled-icon"/>
          <span className="nav-text">Marknadsföring</span>
        </div>
      }
      <Link to="/knowledge-base" className="nav-item">
        <FaSearch className="nav-icon"/>
        <span className="nav-text">Kunskapsbanken</span>
      </Link>
      <Link to="/stats" className="nav-item">
        <FaRegChartBar className="nav-icon"/>
        <span className="nav-text">Statistik</span>
      </Link>
      {currentUserUnitAccess?.roles?.admin ?
        <Link to="/admin" className="nav-item">
          <FaCog className="nav-icon"/>
          <span className="nav-text">Inställningar</span>
        </Link>
      : null}
    </div>
  );
}

export default IconNavigation;